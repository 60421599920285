import React, { FC, useEffect, useState } from 'react';
import {
  Edit,
  BooleanField,
  ResourceComponentPropsWithId,
  useRefresh,
} from 'react-admin';
import { Link } from 'react-router-dom';

import {
  Delete,
  RemoveCircle,
  ThumbUp,
  Edit as EditIcon,
  Done,
  Close,
  SwapHoriz,
} from '@material-ui/icons';
import { Grid } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { makeStyles } from '@material-ui/core/styles';

import { httpClient } from 'httpClient';
import { getUTCDateDifferenceInMinutes } from 'helper/data';

import ModerationStatusBadge from 'layout/ModerationStatusBadge';
import ErrorButton from 'layout/buttons/ErrorButton';
import SuccessButton from 'layout/buttons/SuccessButton';
import GreyButton from 'layout/buttons/GreyButton';
import ToggleActivateButton from 'layout/buttons/ToggleActivateButton';
import CustomSelect from 'layout/input/CustomSelect';
import BooleanFromValueField from 'layout/fields/BooleanFromValueField';

import { gender, logoutTimes } from '../field-choices';

import { UserLong } from 'model-types';
import { PageViews } from './submodules/pageViews';
import { CreditTransactions } from './submodules/creditTransactions';
import { ChangeAge } from './submodules/changeAge';

const useStyles = makeStyles((theme) => ({
  booleanInput: {
    float: 'left',
    margin: '5px 0 0',
  },
}));

const ADD_CREDIT_DEFAULT = {
  amount: '0',
  user_id: 0,
  credits_type: 0,
};

interface RegularUserProps {
  record?: UserLong;
}

interface AddCredits {
  amount: string;
  user_id: number;
  credits_type: number;
}

const UserTitle: FC<RegularUserProps> = ({ record }) =>
  record ? <span>Regular user #{record.id}</span> : null;

const EditForm: FC<RegularUserProps> = ({ record }) => {
  const refresh = useRefresh();

  const [isPasswordEdit, setIsPasswordEdit] = useState<boolean>(false);
  const [isAddCredit, setIsAddCredit] = useState<boolean>(false);
  const [addCredit, setAddCredit] = useState<AddCredits>(ADD_CREDIT_DEFAULT);
  const [password, setPassword] = useState<string>('');
  const [toggleTestStatusLoader, setToggleTestStatusLoader] = useState(false);
  const [userGender, setUserGender] = useState(record?.gender);
  const [selectedLogoutTime, setSelectedLogoutTime] = useState(
    logoutTimes[0].value,
  );

  const classes = useStyles();

  if (!record) {
    return null;
  }

  const ban = () => {
    if (record) {
      httpClient.get('/users/' + record.id + '/ban').then(() => refresh());
    }
  };

  const approve = () => {
    if (record) {
      httpClient.get('/users/' + record.id + '/approve').then(() => refresh());
    }
  };

  const remove = () => {
    if (record && confirm('Delete user #' + record.id + ' ?')) {
      httpClient.delete('/users/' + record.id).then(() => refresh());
    }
  };

  const popular = () => {
    if (
      record &&
      confirm('Swap popular status for user #' + record.id + ' ?')
    ) {
      httpClient
        .get('/users/' + record.id + '/is-popular')
        .then(() => refresh());
    }
  };

  const unsubscribe = () => {
    if (record) {
      httpClient.get('/unsubscribe/' + record.id).then(() => refresh());
    }
  };

  const unsubscribeCredit = () => {
    if (record) {
      httpClient.get('/unsubscribe-credit/' + record.id).then(() => refresh());
    }
  };

  const changePassword = () => {
    if (record) {
      httpClient
        .post('/users/' + record.id + '/change-password', {
          password: password,
        })
        .then(() => {
          setIsPasswordEdit(false);
          refresh();
        });
    }
  };

  const changeGender = () => {
    if (record) {
      httpClient
        .put(`/users/${record.id}`, {
          gender: Number(userGender),
        })
        .then(() => {
          refresh();
        })
        .catch((error) => {
          alert(error.message);
        });
    }
  };

  const changeUserTestStatus = () => {
    if (record) {
      setToggleTestStatusLoader(true);

      const userType = record.trusted_user_id ? 'trusted-user' : 'users';
      const userId = record.trusted_user_id || record.id;

      httpClient
        .put(`/${userType}/${userId}/test`, {
          is_test: !record.is_test,
        })
        .then(() => {
          setToggleTestStatusLoader(false);
          refresh();
        });
    }
  };

  const toggleShowCreditAdd = () => {
    setIsAddCredit((prev) => !prev);
  };

  const handleCreditAmount = (e: any) => {
    setAddCredit((prev) => {
      return { ...prev, amount: e.target.value };
    });
  };

  const handleCreditType = (e: any) => {
    setAddCredit((prev) => {
      return { ...prev, credits_type: e.target.checked ? 1 : 0 };
    });
  };

  const handleAddCredit = () => {
    httpClient
      .post('/payment/add-credits', {
        ...addCredit,
        amount: Number(addCredit.amount) || 0,
      })
      .then(() => {
        toggleShowCreditAdd();
      });
  };

  const handleLogout = () => {
    if (
      confirm(`Log out user # ${record.id} on ${selectedLogoutTime} minutes?`)
    ) {
      httpClient
        .put(`/users/${record.id}/blocked`, {
          blocked_for: selectedLogoutTime,
        })
        .then(() => refresh());
    }
  };

  useEffect(() => {
    setAddCredit((prev) => {
      return { ...prev, user_id: record.id };
    });
  }, []);

  return (
    <Grid container spacing={3} style={{ padding: '20px' }}>
      <Grid item xs={8}>
        <table cellPadding={7}>
          <tbody>
            <tr>
              <td>id</td>
              <td>#{record.id}</td>
            </tr>
            {record.trusted_user_external_id && (
              <tr>
                <td>Carthage TU id</td>
                <td style={{ color: 'rgb(59,130,246)' }}>
                  #{record.trusted_user_external_id}
                </td>
              </tr>
            )}
            {record.ulid_id && (
              <tr>
                <td>ulid</td>
                <td>{record.ulid_id}</td>
              </tr>
            )}
            {record.trusted_user_id && (
              <tr>
                <td>Trusted user</td>
                <td>
                  <GreyButton
                    component={Link}
                    size="small"
                    to={{ pathname: '/trusted-user/' + record.trusted_user_id }}
                  >
                    #{record.trusted_user_id}
                  </GreyButton>
                </td>
              </tr>
            )}
            {record.related_user_ids?.length > 0 && (
              <tr>
                <td>Related users</td>
                <td>
                  {record.related_user_ids.map((user_id) => (
                    <GreyButton
                      component={Link}
                      size="small"
                      style={{ marginRight: '10px' }}
                      to={{ pathname: '/users/' + user_id }}
                    >
                      #{user_id}
                    </GreyButton>
                  ))}
                </td>
              </tr>
            )}
            <tr>
              <td>email</td>
              <td>{record.email}</td>
            </tr>
            <tr>
              <td>site_id</td>
              <td>{record.site_id}</td>
            </tr>
            <tr>
              <td>age</td>
              <td>
                <ChangeAge userAge={record.age} userId={record.id} />
              </td>
            </tr>
            <tr>
              <td>name</td>
              <td>{record.name}</td>
            </tr>
            <tr>
              <td>password</td>
              <td>
                {!isPasswordEdit ? (
                  <>
                    ********
                    <GreyButton
                      id="change-password"
                      size="small"
                      style={{ marginLeft: '10px' }}
                      endIcon={<EditIcon />}
                      onClick={() => setIsPasswordEdit(true)}
                    >
                      Change
                    </GreyButton>
                  </>
                ) : (
                  <>
                    <input
                      type="text"
                      value={password}
                      onInput={(e) => setPassword(e.currentTarget.value)}
                    />
                    <SuccessButton
                      size="small"
                      endIcon={<Done />}
                      onClick={changePassword}
                    >
                      Change
                    </SuccessButton>
                    <GreyButton
                      size="small"
                      endIcon={<Close />}
                      onClick={() => setIsPasswordEdit(false)}
                    >
                      Cancel
                    </GreyButton>
                  </>
                )}
              </td>
            </tr>
            <tr>
              <td>gender</td>
              <td>
                <CustomSelect
                  initialValue={userGender}
                  choices={gender}
                  onChange={setUserGender}
                />
                <SuccessButton size="small" onClick={changeGender}>
                  Save
                </SuccessButton>
              </td>
            </tr>
            <tr>
              <td>moderation_status</td>
              <td>
                <ModerationStatusBadge status={record.moderation_status} />
                <SuccessButton
                  size="small"
                  endIcon={<ThumbUp />}
                  onClick={approve}
                  disabled={record.moderation_status === 'ok'}
                >
                  Approve
                </SuccessButton>
                <ErrorButton
                  size="small"
                  endIcon={<RemoveCircle />}
                  onClick={ban}
                  disabled={record.moderation_status === 'ban'}
                >
                  Ban
                </ErrorButton>
              </td>
            </tr>
            <tr>
              <td>profile_type</td>
              <td>{record.profile_type}</td>
            </tr>
            <tr>
              <td>is_deleted</td>
              <td>
                <BooleanField
                  record={record}
                  source="is_deleted"
                  className={classes.booleanInput}
                />
                <ErrorButton
                  size="small"
                  endIcon={<Delete />}
                  onClick={remove}
                  disabled={record.is_deleted}
                >
                  Delete
                </ErrorButton>
              </td>
            </tr>
            <tr>
              <td>is_popular</td>
              <td>
                <BooleanField
                  record={record}
                  source="is_popular"
                  className={classes.booleanInput}
                />
                <ErrorButton
                  size="small"
                  endIcon={<SwapHoriz />}
                  onClick={popular}
                >
                  Swap popular
                </ErrorButton>
              </td>
            </tr>
            <tr>
              <td>Logout user on</td>
              <td>
                <CustomSelect
                  choices={logoutTimes}
                  onChange={setSelectedLogoutTime}
                />
                <ErrorButton size="small" onClick={handleLogout}>
                  Log out
                </ErrorButton>
              </td>
              {record.blocked_for_at &&
                getUTCDateDifferenceInMinutes(record.blocked_for_at) > 0 && (
                  <td>
                    User blocked at{' '}
                    {getUTCDateDifferenceInMinutes(record.blocked_for_at)}{' '}
                    minutes
                  </td>
                )}
            </tr>
            <tr>
              <td>photo_count</td>
              <td>{record.photo_count}</td>
            </tr>
            <tr>
              <td>created_at</td>
              <td>{record.created_at}</td>
            </tr>
            <tr>
              <td>city</td>
              <td>{record.city}</td>
            </tr>
            <tr>
              <td>country</td>
              <td>{record.country}</td>
            </tr>
            <tr>
              <td>is_test</td>
              <td>
                <BooleanFromValueField source="is_test" />
                <ToggleActivateButton
                  isActivate={!record.is_test}
                  handleActivate={changeUserTestStatus}
                  handleDeactivate={changeUserTestStatus}
                  isLoader={toggleTestStatusLoader}
                />
              </td>
            </tr>
            <tr>
              <td>is_premium</td>
              <td>
                <BooleanField
                  record={record}
                  source="is_premium"
                  className={classes.booleanInput}
                />
                <ErrorButton
                  size="small"
                  endIcon={<RemoveCircle />}
                  onClick={unsubscribe}
                  disabled={!record.has_regular_subscription}
                >
                  Unsubscribe
                </ErrorButton>
              </td>
            </tr>
            <tr>
              <td>credits</td>
              <td>
                {record.credits}
                <ErrorButton
                  size="small"
                  endIcon={<RemoveCircle />}
                  onClick={unsubscribeCredit}
                  disabled={!record.has_credit_subscription}
                >
                  Stop delayed payment
                </ErrorButton>
              </td>
            </tr>
            <tr>
              <td>is_chb</td>
              <td>
                <BooleanField
                  record={record}
                  source="is_chb"
                  className={classes.booleanInput}
                />
              </td>
            </tr>
            <tr>
              <td>balance</td>
              <td>
                {!isAddCredit ? (
                  <>
                    0000
                    <SuccessButton
                      size="small"
                      style={{ marginLeft: '10px' }}
                      endIcon={<EditIcon />}
                      onClick={toggleShowCreditAdd}
                    >
                      Add credit
                    </SuccessButton>
                  </>
                ) : (
                  <>
                    <input
                      type="number"
                      value={addCredit.amount}
                      onInput={handleCreditAmount}
                    />
                    <FormControlLabel
                      control={
                        <Switch color="primary" onChange={handleCreditType} />
                      }
                      labelPlacement="top"
                      label="Is free"
                    />

                    <SuccessButton
                      size="small"
                      endIcon={<Done />}
                      onClick={handleAddCredit}
                    >
                      Add
                    </SuccessButton>
                    <GreyButton
                      size="small"
                      endIcon={<Close />}
                      onClick={toggleShowCreditAdd}
                    >
                      Cancel
                    </GreyButton>
                  </>
                )}
              </td>
            </tr>
          </tbody>
        </table>
      </Grid>

      <Grid item xs={4}>
        <img src={record.thumbnail} style={{ width: '100%' }} />
      </Grid>

      <Grid item xs={12}>
        <PageViews userId={record.id} />
      </Grid>

      <Grid item xs={12}>
        <CreditTransactions userId={record.id} />
      </Grid>
    </Grid>
  );
};

const RegularUserEdit: FC<ResourceComponentPropsWithId> = (props) => {
  return (
    <Edit title={<UserTitle />} {...props}>
      <EditForm />
    </Edit>
  );
};

export default RegularUserEdit;
