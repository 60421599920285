import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Toolbar, useNotify, useRedirect, useRefresh } from 'react-admin';
import { useHistory, useLocation } from 'react-router';
import queryString from 'query-string';
import { makeStyles } from '@material-ui/core';

import { httpClient } from 'httpClient';
import { EventEmitter } from 'eventEmitter';

import SuccessButton from 'layout/buttons/SuccessButton';
import GreyButton from 'layout/buttons/GreyButton';
import YellowButton from 'layout/buttons/YellowButton';

import DeclinePopup from './DeclinePopup';
import SubmitBtn from './SubmitBtn';

import {
  TrustedUser,
  TrustedUserMedia,
  TrustedUserModerationData,
} from 'types/trustedUser/trustedUserModeration';
import { EmitterEvents } from 'types/enums/EmitterEvents';

import {
  DEFAULT_MODERATION_RESPONSE,
  GET_TUB_NUMBER_REG_EXP,
} from '../constants';
import { ModerationStatus } from 'types/enums/ModerationStatus';

const useStyles = makeStyles((theme) => ({
  btnsContainer: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 'auto',
  },
  draftBtn: {
    margin: '0 10px',
  },
}));

interface Props {
  record: TrustedUser;
  isDraftSaving: boolean;
  moderationData: TrustedUserModerationData;
  setModerationData: Dispatch<SetStateAction<TrustedUserModerationData>>;
  saveDraft: () => void;
}

export const CustomToolbar: React.FC<Props> = ({
  record,
  isDraftSaving,
  moderationData,
  setModerationData,
  saveDraft,
}: any) => {
  const history = useHistory();
  const notify = useNotify();
  const redirect = useRedirect();
  const refresh = useRefresh();
  const classes = useStyles();

  const { pathname, search } = useLocation();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [tabNumber, setTubNumber] = useState(0);

  const openNextTab = () => {
    const newUrl = tabNumber
      ? pathname.replace(GET_TUB_NUMBER_REG_EXP, String(tabNumber + 1))
      : pathname + '/1';

    history.push(`${newUrl}${search}`);
  };

  const openPrevTab = () => {
    const newUrl =
      tabNumber > 1
        ? pathname.replace(GET_TUB_NUMBER_REG_EXP, String(tabNumber - 1))
        : pathname.replace(/\/(?<=\d\/)\d+$/, '');

    history.push(newUrl);
  };

  const checkIsAllMediaModerated = () => {
    const isAllPublicPhotoModerated = moderationData.public_photos.find(
      (photo: TrustedUserMedia) =>
        photo.status_id !== ModerationStatus.Ok &&
        photo.status_id !== ModerationStatus.Ban,
    );

    if (isAllPublicPhotoModerated) {
      notify(`Moderate all public photo for submit`, 'error');

      return false;
    }

    const isAllPrivatePhotoModerated = moderationData.private_photos.find(
      (photo: TrustedUserMedia) =>
        photo.status_id !== ModerationStatus.Ok &&
        photo.status_id !== ModerationStatus.Ban,
    );

    if (isAllPrivatePhotoModerated) {
      notify(`Moderate all private photo for submit`, 'error');

      return false;
    }

    const isAllPrivatePhotoLibraryModerated =
      moderationData.private_library.find(
        (photo: TrustedUserMedia) =>
          photo.status_id !== ModerationStatus.Ok &&
          photo.status_id !== ModerationStatus.Ban,
      );

    if (isAllPrivatePhotoLibraryModerated) {
      notify(`Moderate all private library for submit`, 'error');

      return false;
    }

    const isAllVideosModerated = moderationData.videos.find(
      (video: TrustedUserMedia) =>
        video.status_id !== ModerationStatus.Ok &&
        video.status_id !== ModerationStatus.Ban,
    );

    if (isAllVideosModerated) {
      notify(`Moderate all video for submit`, 'error');

      return false;
    }

    return true;
  };

  const handleSubmitBtn = async () => {
    if (!checkIsAllMediaModerated()) {
      return;
    }

    setIsSubmitting(true);

    await saveDraft();

    const publicPhotosModerationData = moderationData.public_photos.map(
      (mediaData: TrustedUserMedia) => {
        return {
          id: mediaData.id,
          status_id: mediaData.status_id,
          is_erotic: mediaData.is_erotic,
        };
      },
    );
    const privatePhotosModerationData = moderationData.private_photos.map(
      (mediaData: TrustedUserMedia) => {
        return {
          id: mediaData.id,
          status_id: mediaData.status_id,
          is_erotic: mediaData.is_erotic,
        };
      },
    );
    const privateLibraryModerationData = moderationData.private_library.map(
      (mediaData: TrustedUserMedia) => {
        return {
          id: mediaData.id,
          status_id: mediaData.status_id,
          is_erotic: mediaData.is_erotic,
        };
      },
    );
    const videosModerationData = moderationData.videos.map(
      (mediaData: TrustedUserMedia) => {
        return {
          id: mediaData.id,
          status_id: mediaData.status_id,
          is_erotic: mediaData.is_erotic,
        };
      },
    );

    const moderationRequestPayload = {
      ...moderationData,
      public_photos: publicPhotosModerationData,
      private_photos: privatePhotosModerationData,
      private_library: privateLibraryModerationData,
      videos: videosModerationData,
    };

    const path = pathname.includes('re-moderation')
      ? 're-moderation'
      : 'moderation';

    httpClient
      .post(
        `/trusted-user/moderation/${record.id}`,
        { ...moderationRequestPayload },
        { timeout: 60 * 1000 },
      )
      .then(() => {
        notify('User moderation saved', 'info');

        httpClient.get(`/trusted-user/${path}`).then((response) => {
          EventEmitter.dispatch(EmitterEvents.ReloadModerationCounters);

          if (response.data?.data?.[0]) {
            const redirectLink = queryString.stringifyUrl({
              url: `/trusted-user/${path}/${response.data.data[0].id}`,
            });

            redirect(redirectLink);
          } else {
            redirect(`/trusted-user/${path}`);
          }
        });

        setModerationData(DEFAULT_MODERATION_RESPONSE);
      })
      .catch((error) => {
        if (error?.response?.data?.retrieved_at) {
          refresh();

          notify(error?.response?.data?.retrieved_at, 'error', {
            multiLine: true,
          });

          return;
        }

        !!error?.response?.data
          ? Object.keys(error.response.data).forEach((errorKey) =>
              notify(error.response.data[errorKey], 'error', {
                multiLine: true,
              }),
            )
          : notify(error.message, 'error');
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  useEffect(() => {
    const tabNumberFromUrl = pathname.match(GET_TUB_NUMBER_REG_EXP);
    const tubNumber = tabNumberFromUrl ? Number(tabNumberFromUrl[0]) : 0;

    setTubNumber(tubNumber);
  }, [pathname]);

  return (
    <Toolbar
      style={{
        position: 'sticky',
        bottom: '0',
        marginTop: 'auto',
      }}
    >
      {tabNumber < 2 && (
        <DeclinePopup
          tabNumber={tabNumber}
          moderationData={moderationData}
          setModerationData={setModerationData}
        />
      )}

      <div className={classes.btnsContainer}>
        <GreyButton
          onClick={saveDraft}
          loading={isDraftSaving}
          className={classes.draftBtn}
        >
          Save Progress
        </GreyButton>

        {!!tabNumber && <YellowButton onClick={openPrevTab}>Prev</YellowButton>}

        {tabNumber < 5 && (
          <SuccessButton onClick={openNextTab}>Next</SuccessButton>
        )}

        {tabNumber === 5 && (
          <SubmitBtn
            isSubmitting={isSubmitting}
            handleSubmitBtn={handleSubmitBtn}
          />
        )}
      </div>
    </Toolbar>
  );
};

export default CustomToolbar;
