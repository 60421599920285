import { httpClient } from 'httpClient';

export interface SiteIdChoices {
  front_url: string;
  id: number;
  name: string;
  site_group_id: number;
}

interface ModerationCountersResponse {
  icebreaker_moderation: number;
  photo_moderation_counter: number;
  trusted_user_moderation: number;
  trusted_user_remoderation: number;
}

export const PidvalApi = {
  fetchMirrorShortInfo: async () => {
    const { data } = await httpClient.get<SiteIdChoices[]>(
      `/site/choices?site_group_id=1`,
    );

    return data;
  },

  fetchModerationCounters: async () => {
    const { data } = await httpClient.get<ModerationCountersResponse>(
      `/trusted-user/moderation-count`,
    );

    return data;
  },

  fetchSupplyListForIcebreakerConfig: async () => {
    const { data } = await httpClient.get(`/ice-breaker/config/supply-list`);

    return data.data;
  },

  fetchSupplyListForActivityConfig: async () => {
    const { data } = await httpClient.get(`/activity/config/supply-list`);

    return data.data;
  },

  activateTuNewStatus: async (payload: {
    status: boolean;
    trusted_user_id: number;
    new_from?: string;
  }) => {
    const { data } = await httpClient.post(
      `/trusted-user/facilitate-rule`,
      payload,
    );

    return data.data;
  },
};
