import { FC, useCallback, useEffect, useState } from 'react';
import { Edit, TabbedForm, FormTab } from 'react-admin';

import { Card } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

import { httpClient } from 'httpClient';

import { DocumentSection } from 'component/trustedUser/DocumentSection';
import { OtherDetailsSection } from 'component/trustedUser/OtherDetailsSection';
import { ShortInfoBar } from 'component/trustedUser/ShortInfoBar';
import MediaList from './submodules/MediaList';
import CustomToolbar from './submodules/CustomToolbar';

import {
  TrustedUser,
  TrustedUserMedia,
  TrustedUserModerationData,
} from 'types/trustedUser/trustedUserModeration';
import { DEFAULT_MODERATION_RESPONSE } from './constants';

interface UserProps {
  record?: TrustedUser;
}

const useStyles = makeStyles((theme) => ({
  trustedUserBox: {
    display: 'flex',
    justifyContent: 'space-between',
    height: '90vh',
    maxWidth: '1400px',
    backgroundColor: '#fff',
  },
  tabbedForm: {
    width: '78%',
    maxWidth: '1000px',
    height: '100%',
  },
  tab: {
    minWidth: '16.66%',
    width: '16.66%',
  },
  loader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
    margin: '150px 0',
  },
}));

const applyMediaDraft = (
  media: TrustedUserMedia[],
  draftMedia: TrustedUserMedia[],
) => {
  if (!draftMedia?.length) return media;

  return media.map((mediaItem) => {
    const draftItem = draftMedia.find((draft) => draft.id === mediaItem.id);

    if (draftItem) return { ...mediaItem, ...draftItem };

    return mediaItem;
  });
};

const UserTitle: FC<UserProps> = ({ record }) =>
  record ? <span>Trusted user #{record.id}</span> : null;

// TODO use api from PidvalApi instead of httpClient
// TODO fix console errors

const EditForm: FC<UserProps> = ({ record }) => {
  const [isDraftSaving, setIsDraftSaving] = useState(false);
  const [moderationData, setModerationData] =
    useState<TrustedUserModerationData>(DEFAULT_MODERATION_RESPONSE);

  const classes = useStyles();

  const setPublicPhotos = useCallback(
    (setStateAction: (prevMedia: TrustedUserMedia[]) => TrustedUserMedia[]) => {
      setModerationData((prevModerationData) => ({
        ...prevModerationData,
        public_photos: setStateAction(prevModerationData.public_photos),
      }));
    },
    [],
  );

  const setPrivatePhotos = useCallback(
    (setStateAction: (prevMedia: TrustedUserMedia[]) => TrustedUserMedia[]) => {
      setModerationData((prevModerationData) => ({
        ...prevModerationData,
        private_photos: setStateAction(prevModerationData.private_photos),
      }));
    },
    [],
  );

  const setPrivateLibrary = useCallback(
    (setStateAction: (prevMedia: TrustedUserMedia[]) => TrustedUserMedia[]) => {
      setModerationData((prevModerationData) => ({
        ...prevModerationData,
        private_library: setStateAction(prevModerationData.private_library),
      }));
    },
    [],
  );

  const setVideos = useCallback(
    (setStateAction: (prevMedia: TrustedUserMedia[]) => TrustedUserMedia[]) => {
      setModerationData((prevModerationData) => ({
        ...prevModerationData,
        videos: setStateAction(prevModerationData.videos),
      }));
    },
    [],
  );

  const loadModerationDraft = useCallback(async (userId: number) => {
    const data = await httpClient.get<TrustedUserModerationData>(
      `/trusted-user/moderation/${userId}/draft`,
    );

    return { draft: data.data };
  }, []);

  const saveDraft = useCallback(async () => {
    if (!record?.id || !moderationData?.retrieved_at) return;

    setIsDraftSaving(true);

    try {
      await httpClient.post(
        `/trusted-user/moderation/${record.id}/draft`,
        moderationData,
      );
    } finally {
      setIsDraftSaving(false);
    }
  }, [record?.id, moderationData]);

  useEffect(() => {
    if (
      !record?.id ||
      !record?.public_photos ||
      !record?.private_photos ||
      !record?.private_library
    )
      return;

    const retrieved_at = Math.floor(new Date().getTime() / 1000);

    loadModerationDraft(record.id)
      .then(({ draft }) => {
        setModerationData((initData) => ({
          document_decline_reason:
            draft.document_decline_reason || initData.document_decline_reason,
          document_status: !draft.document_decline_reason,
          other_detail_decline_reason:
            draft.other_detail_decline_reason ||
            initData.other_detail_decline_reason,
          other_detail_status: !draft.other_detail_decline_reason,

          public_photos: applyMediaDraft(
            record.public_photos,
            draft.public_photos,
          ),
          private_photos: applyMediaDraft(
            record.private_photos,
            draft.private_photos,
          ),
          private_library: applyMediaDraft(
            record.private_library,
            draft.private_library,
          ),
          videos: applyMediaDraft(record.videos, draft.videos),

          retrieved_at,
        }));
      })
      .catch(() => {
        setModerationData((initData) => ({
          ...initData,
          public_photos: record.public_photos,
          private_photos: record.private_photos,
          private_library: record.private_library,
          videos: record.videos,
          retrieved_at,
        }));
      });
  }, [loadModerationDraft, record]);

  // ? save draft with debounce
  useEffect(() => {
    const handler = setTimeout(saveDraft, 7000);

    return () => clearTimeout(handler);
  }, [saveDraft]);

  if (!record || !moderationData.retrieved_at)
    return (
      <div className={classes.loader}>
        <CircularProgress color="secondary" size="30px" />
      </div>
    );

  return (
    <div className={classes.trustedUserBox}>
      <ShortInfoBar isModeration />

      <Card className={classes.tabbedForm}>
        <TabbedForm
          toolbar={
            <CustomToolbar
              record={record}
              moderationData={moderationData}
              setModerationData={setModerationData}
              saveDraft={saveDraft}
              isDraftSaving={isDraftSaving}
            />
          }
          submitOnEnter={false}
          style={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            overflowY: 'scroll',
          }}
        >
          <FormTab
            label="Documents"
            className={classes.tab}
            style={{
              color: moderationData.document_decline_reason
                ? '#d32f2f'
                : 'inherit',
              fontWeight: moderationData.document_decline_reason
                ? 'bold'
                : 'inherit',
            }}
          >
            <DocumentSection record={record} />
          </FormTab>

          <FormTab
            label="Other Details"
            className={classes.tab}
            style={{
              color: moderationData.other_detail_decline_reason
                ? '#d32f2f'
                : 'inherit',
              fontWeight: moderationData.other_detail_decline_reason
                ? 'bold'
                : 'inherit',
            }}
          >
            <OtherDetailsSection record={record} />
          </FormTab>

          <FormTab label="Public Photos" className={classes.tab}>
            <MediaList
              media={moderationData.public_photos}
              setMedia={setPublicPhotos}
              tabNumber={2}
            />
          </FormTab>

          <FormTab label="Private Photos" className={classes.tab}>
            <MediaList
              media={moderationData.private_photos}
              setMedia={setPrivatePhotos}
              tabNumber={3}
            />
          </FormTab>

          <FormTab label="Private library" className={classes.tab}>
            <MediaList
              media={moderationData.private_library}
              setMedia={setPrivateLibrary}
              tabNumber={4}
            />
          </FormTab>

          <FormTab label="Videos" className={classes.tab}>
            <MediaList
              media={moderationData.videos}
              setMedia={setVideos}
              tabNumber={5}
            />
          </FormTab>
        </TabbedForm>
      </Card>
    </div>
  );
};

const TrustedUserModeration = (props: any) => {
  return (
    <Edit {...props} title={<UserTitle />} component="div">
      <EditForm />
    </Edit>
  );
};

export default TrustedUserModeration;
