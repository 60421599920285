export const DEFAULT_MODERATION_RESPONSE = {
  retrieved_at: 0,
  document_status: true,
  document_decline_reason: '',
  other_detail_status: true,
  other_detail_decline_reason: '',
  public_photos: [],
  private_photos: [],
  private_library: [],
  videos: [],
};

export const TABS = [
  'document_status',
  'other_detail_status',
  'public_photos',
  'private_photos',
  'private_library',
  'videos',
];

export const TAB_DECLINE_FIELD: { [key: string]: string } = {
  document_status: 'document_decline_reason',
  other_detail_status: 'other_detail_decline_reason',
};

export const GET_TUB_NUMBER_REG_EXP = /(?<=\d\/)\d+$/;
