import React, { useCallback, useEffect, useState } from 'react';
import {
  Datagrid,
  DateField,
  List,
  NumberField,
  TextField,
  ResourceComponentProps,
  Identifier,
  Filter,
  SelectArrayInput,
} from 'react-admin';
import { useLocation } from 'react-router';

import { makeStyles } from '@material-ui/core/styles';
import CustomPagination from 'layout/Pagination';
import { getArrayFromLinkedList } from 'utils';

const useStyles = makeStyles((theme) => ({
  idCell: {
    width: '30px',
    textAlign: 'center',
  },
}));

const UserFilter = (props: any) => {
  const [suppliesSelectChoses, setSuppliesSelectChoses] = useState<any[]>([]);

  const fetchSupplies = async () => {
    const suppliesArray = await getArrayFromLinkedList('/supply');

    const supplyIdList = suppliesArray.map((element) => ({
      id: element.id,
      name: element.id,
    }));

    setSuppliesSelectChoses(supplyIdList);
  };

  useEffect(() => {
    fetchSupplies();
  }, []);

  return (
    <Filter {...props} style={{ maxWidth: '1000px' }}>
      <SelectArrayInput
        alwaysOn
        label="Supply id"
        source="supply_id"
        choices={suppliesSelectChoses}
        translateChoice={false}
        style={{ width: '120px' }}
      />
    </Filter>
  );
};

const TrustedUserModerationList = (props: ResourceComponentProps) => {
  const classes = useStyles();

  const { pathname } = useLocation();

  const handleRowClick = useCallback((userId: Identifier) => {
    if (pathname.includes('re-moderation')) {
      return `/trusted-user/re-moderation/${userId}`;
    }

    return `/trusted-user/moderation/${userId}`;
  }, []);

  return (
    <List
      {...props}
      filters={<UserFilter />}
      perPage={15}
      sort={{ field: 'id', order: 'DESC' }}
      pagination={<CustomPagination />}
      bulkActionButtons={false}
    >
      <Datagrid optimized rowClick={handleRowClick}>
        <NumberField
          source="id"
          label="id"
          cellClassName={classes.idCell}
          options={{ useGrouping: false }}
          sortable={false}
          textAlign="center"
        />

        <NumberField
          source="supply.id"
          label="Supply id"
          cellClassName={classes.idCell}
          options={{ useGrouping: false }}
          sortable={false}
          textAlign="center"
        />

        <NumberField
          label="Carthage id"
          source="external_id"
          cellClassName={classes.idCell}
          style={{ color: 'rgb(59,130,246)' }}
          options={{ useGrouping: false }}
          sortable={false}
          textAlign="center"
        />
        <TextField label="Email" source="documents.email" sortable={false} />
        <NumberField
          source="documents.date_of_birth"
          label="Date of birth"
          sortable={false}
          textAlign="center"
        />
        <TextField label="Name" source="documents.name" sortable={false} />
        <TextField
          label="City"
          source="documents.city_id.name"
          sortable={false}
        />
        <DateField
          source="created_at"
          locales="ru-RU"
          showTime
          sortable={false}
        />
      </Datagrid>
    </List>
  );
};

export default TrustedUserModerationList;
