import React from 'react';
import { FC } from 'react';
import {
  Create,
  useRedirect,
  useNotify,
  ResourceComponentProps,
} from 'react-admin';
import ActivityConfigForm from './ActivityConfigForm';

const ActivityConfigCreate: FC<ResourceComponentProps> = (props) => {
  const notify = useNotify();
  const redirect = useRedirect();

  const onSuccess = () => {
    notify('Config created');

    redirect('list', props.basePath);
  };

  return (
    <Create title=" activity config" onSuccess={onSuccess} {...props}>
      <ActivityConfigForm />
    </Create>
  );
};

export default ActivityConfigCreate;
