import React, { useState } from 'react';
import { DateField, Toolbar } from 'react-admin';
import { httpClient } from 'httpClient';
import { makeStyles } from '@material-ui/core';

import CustomSelect from 'layout/input/CustomSelect';
import ErrorButton from 'layout/buttons/ErrorButton';
import { logoutTimes } from '../../field-choices';

const useStyles = makeStyles((theme) => ({
  toolbar: {
    position: 'sticky',
    bottom: '0',
    marginTop: 'auto',
  },
  container: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
}));

export const CustomToolbar = ({ record }: any) => {
  const classes = useStyles();

  const [selectedLogoutTime, setSelectedLogoutTime] = useState(
    logoutTimes[0].value,
  );

  const [isForceLogout, setIsForceLogout] = useState(false);

  const handleLogout = () => {
    if (
      confirm(`Log out user # ${record.id} on ${selectedLogoutTime} minutes?`)
    ) {
      httpClient
        .get(`/trusted-user/${record.id}/logout?block_on=${selectedLogoutTime}`)
        .then((response) => {
          if (response.status) {
            setIsForceLogout(true);
          }
        });
    }
  };

  return (
    <Toolbar className={classes.toolbar}>
      <div className={classes.container}>
        <div>
          Last login:&#160;
          <DateField
            source="last_login"
            locales="ru-RU"
            showTime
            sortable={false}
          />
        </div>

        <div>
          <p>
            Logout user on
            <CustomSelect
              choices={logoutTimes}
              onChange={setSelectedLogoutTime}
            />
            <ErrorButton
              size="small"
              onClick={handleLogout}
              disabled={isForceLogout || !record.is_online}
            >
              Log out
            </ErrorButton>
          </p>
        </div>
      </div>
    </Toolbar>
  );
};

export default CustomToolbar;
