import { Favicon } from 'faviconConstants';
import { useFavicon } from 'hooks/useFavicon';
import {
  createContext,
  FC,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useLocation } from 'react-router';
import { ModerationCounters } from 'types/ModerationCouters';

const moderationTabs = [
  Favicon.PhotoModeration,
  Favicon.TrustedUserModeration,
  Favicon.TrustedUserReModeration,
  Favicon.IceBreakerModeration,
];

export const FaviconContext = createContext<{
  setFavicon: (favicon: string) => void;
  setCurrentCounterData: (currentData: ModerationCounters) => void;
}>({
  setFavicon: () => {},
  setCurrentCounterData: () => {},
});

export const FaviconProvider: FC = ({ children }) => {
  const [favicon, setFavicon] = useState('');
  const [currentCounterData, setCurrentCounterData] =
    useState<ModerationCounters>();
  const [faviconNotificationCount, setFaviconNotificationCount] = useState<
    number | null
  >(null);

  const { pathname } = useLocation();

  const moderationTab = useMemo(
    () => moderationTabs.find((el) => pathname.includes(el)) || '',
    [pathname],
  );

  useFavicon({
    faviconUrl: favicon,
    isModerationTab: !!moderationTab,
    activeTabCount: faviconNotificationCount,
  });

  const handleSetFavicon = useCallback((favicon: string) => {
    setFavicon(favicon);
  }, []);

  const handleSetCurrentCounterData = useCallback(
    (currentCounterData: ModerationCounters) => {
      setCurrentCounterData(currentCounterData);
    },
    [],
  );

  useEffect(() => {
    if (currentCounterData) {
      switch (moderationTab) {
        case Favicon.PhotoModeration:
          setFaviconNotificationCount(currentCounterData.userPhotosModeration);
          break;

        case Favicon.TrustedUserModeration:
          setFaviconNotificationCount(
            currentCounterData.trustedUsersModeration,
          );
          break;

        case Favicon.TrustedUserReModeration:
          setFaviconNotificationCount(
            currentCounterData.trustedUsersReModeration,
          );
          break;

        case Favicon.IceBreakerModeration:
          setFaviconNotificationCount(currentCounterData.icebreakersModeration);
          break;

        default:
          setFaviconNotificationCount(null);
          break;
      }
    }
  }, [currentCounterData, moderationTab]);

  const defaultValue = useMemo(
    () => ({
      setFavicon: handleSetFavicon,
      setCurrentCounterData: handleSetCurrentCounterData,
    }),
    [handleSetFavicon, handleSetCurrentCounterData],
  );

  return (
    <FaviconContext.Provider value={defaultValue}>
      {children}
    </FaviconContext.Provider>
  );
};
