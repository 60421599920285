import React, { FC } from 'react';

import { ImageListItemBar } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import { Checkbox } from '@material-ui/core';

import { ReactComponent as Erotic } from 'assets/icons/erotic.svg';
import { ModerationStatus } from 'types/enums/ModerationStatus';

interface Props {
  item: { id: number; status_id: ModerationStatus; is_erotic: boolean };
  isEroticVisible?: boolean;
}

const MediaModerationStatus: FC<Props> = ({ item, isEroticVisible }) => {
  return (
    <ImageListItemBar
      actionIcon={
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <CancelIcon
            data-approve-btn="cancel"
            style={{
              fill:
                item.status_id === ModerationStatus.Ban ? 'red' : '#ee393952',
              cursor: 'pointer',
              width: '50px',
              height: '50px',
            }}
          />{' '}
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            {isEroticVisible && (
              <Checkbox
                icon={
                  <div
                    style={{
                      width: '38px',
                      height: '38px',
                      border: '2px solid #d616167d',
                      borderRadius: '50%',
                    }}
                  />
                }
                checkedIcon={<Erotic width="38" />}
                checked={item.is_erotic}
              />
            )}
            <CheckCircleIcon
              style={{
                fill:
                  item.status_id === ModerationStatus.Ok
                    ? '#57d45c'
                    : '#56d22757',
                cursor: 'pointer',
                width: '50px',
                height: '50px',
              }}
            />
          </div>
        </div>
      }
    />
  );
};

export default MediaModerationStatus;
