import React from 'react';
import {
  Datagrid,
  List,
  TextField,
  NumberField,
  FunctionField,
  DateField,
  ImageField,
  ArrayField,
  SimpleList,
} from 'react-admin';
import CustomPagination from 'layout/Pagination';
import { makeStyles } from '@material-ui/core/styles';

import { IceBreaker } from 'model-types';
import { ModerationBtns } from 'component/common/ModerationBtns/ModerationBtns';
import IceBreakerType from 'layout/IceBreakerType';

const useStyles = makeStyles((theme) => ({
  idCell: {
    width: '30px',
    textAlign: 'center',
  },
  imageCell: {
    maxWidth: '170px',
    width: '100%',
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(50px, 1fr))',
    gridGap: '5px',
  },
  imageCellItem: {
    width: '100%',
    margin: 0,
    objectFit: 'contain',
  },
  childrens: {
    minWidth: '160px',
    width: '160px',
  },
}));

const IceBreakerList = (props: IceBreaker) => {
  const classes = useStyles();

  return (
    <>
      <List
        perPage={15}
        sort={{ field: 'updated_at', order: 'DESC' }}
        pagination={<CustomPagination />}
        bulkActionButtons={false}
        {...props}
      >
        <Datagrid optimized>
          <NumberField
            source="id"
            cellClassName={classes.idCell}
            options={{ useGrouping: false }}
            sortable={false}
            textAlign="center"
          />
          <NumberField
            label="TU id"
            source="trusted_user.id"
            cellClassName={classes.idCell}
            options={{ useGrouping: false }}
            sortable={false}
          />
          <NumberField
            label="Carthage TU id"
            source="trusted_user.external_id"
            cellClassName={classes.idCell}
            style={{ color: 'rgb(59,130,246)' }}
            options={{ useGrouping: false }}
            sortable={false}
            textAlign="center"
          />
          <TextField source="message" sortable={false} />

          <ArrayField
            source="childrens"
            label="Childrens"
            sortable={false}
            cellClassName={classes.childrens}
          >
            <SimpleList
              primaryText={(record) => record.message}
              secondaryText={(record) => `Delay: ${record.delay}`}
              linkType={false}
            />
          </ArrayField>

          <ImageField
            source="media"
            src="big_url"
            classes={{
              list: classes.imageCell,
              image: classes.imageCellItem,
            }}
            emptyText="No images"
          />

          <FunctionField
            label="Type"
            render={(record?: Record<string, any>) => (
              <IceBreakerType record={record} />
            )}
          />
          <DateField source="created_at" locales="UK" sortable={false} />
          <ModerationBtns />
        </Datagrid>
      </List>
    </>
  );
};

export default IceBreakerList;
