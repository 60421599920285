import React from 'react';
import { Chip } from '@material-ui/core';
import { IceBreakersTypes } from 'types/enums/IceBreakersTypes';
import { IceBreakersTypesCaptions } from 'types/icebreaker';

const IceBreakerType = ({ record }: { record?: Record<string, any> }) => {
  switch (record?.type) {
    case IceBreakersTypes.Chat:
      return (
        <Chip
          label={IceBreakersTypesCaptions[IceBreakersTypes.Chat]}
          style={{ backgroundColor: 'rgb(220, 252, 231)' }}
        />
      );
    case IceBreakersTypes.Mail:
      return (
        <Chip
          label={IceBreakersTypesCaptions[IceBreakersTypes.Mail]}
          style={{ backgroundColor: 'rgb(224,242,254)' }}
        />
      );
    case IceBreakersTypes.MissedMessage:
      return (
        <Chip
          label={IceBreakersTypesCaptions[IceBreakersTypes.MissedMessage]}
          style={{ backgroundColor: 'rgb(254,249,195)' }}
        />
      );
    case IceBreakersTypes.ChatFollowUp:
      return (
        <Chip
          label={IceBreakersTypesCaptions[IceBreakersTypes.ChatFollowUp]}
          style={{ backgroundColor: 'rgb(249, 220, 252)' }}
        />
      );
    case IceBreakersTypes.ChatOnline:
      return (
        <Chip
          label={IceBreakersTypesCaptions[IceBreakersTypes.ChatOnline]}
          style={{ backgroundColor: 'rgb(252, 240, 220)' }}
        />
      );
    default:
      return <></>;
  }
};

export default IceBreakerType;
