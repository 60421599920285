export enum IceBreakersTypes {
  Chat = 1,
  Mail = 2,
  MissedMessage = 3,
  ChatFollowUp = 4,
  ChatOnline = 5,
}

export enum IceBreakerDeclineReason {
  DUPLICATE = 1,
  EXCESSIVE_PASSION = 2,
  ADULT_CONTENT = 3,
  USER_SPECIFIC = 4,
  REAL_MEETING = 5,
  FORBIDDEN_CONTENT = 6,
  CONTACT_INFO = 7,
  EXTORTION = 8,
  FORMATTING_ISSUE = 9,
  WRONG_TU_INFO = 10,
  NON_ENGLISH = 11,
}
