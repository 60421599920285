import React, { useState } from 'react';
import { useRecordContext, useRefresh } from 'react-admin';
import { httpClient } from 'httpClient';

import ErrorButton from 'layout/buttons/ErrorButton';
import SuccessButton from 'layout/buttons/SuccessButton';
import { IceBreakerModerationStatus } from 'model-types';
import { EventEmitter } from 'eventEmitter';
import { EmitterEvents } from 'types/enums/EmitterEvents';
import { ConfirmationDialog } from 'component/iceBreaker/ConfirmationDialog';
import { IceBreakerDeclineReason } from 'types/enums/IceBreakersTypes';

export const ModerationBtns = () => {
  const record = useRecordContext();
  const refresh = useRefresh();

  const [isModerating, setIsModerating] = useState(false);
  const [isConfirmationPopupOopen, setIsConfirmationPopupOpen] =
    useState(false);

  const handleModerate = ({
    reason,
    moderationStatus,
  }: {
    reason?: IceBreakerDeclineReason;
    moderationStatus: number;
  }) => {
    setIsModerating(true);

    httpClient
      .post(`/ice-breaker/moderation/${record.id}`, {
        moderation_status: moderationStatus,
        ...(reason && { decline_reason: reason }),
      })
      .then(() => {
        EventEmitter.dispatch(EmitterEvents.ReloadModerationCounters);
      })
      .finally(() => {
        setIsModerating(false);
        refresh();
      });
  };

  const handleDecline = () => {
    setIsConfirmationPopupOpen(true);
  };

  if (!record) return null;

  return (
    <>
      <SuccessButton
        disabled={!!record.is_deleted || isModerating}
        onClick={() =>
          handleModerate({
            moderationStatus: IceBreakerModerationStatus.Approve,
          })
        }
        style={{
          minWidth: '100px',
          margin: '4px',
          opacity:
            record.moderation_status === IceBreakerModerationStatus.Decline
              ? '0.3'
              : '1',
        }}
      >
        Approve
      </SuccessButton>

      <ErrorButton
        disabled={!!record.is_deleted || isModerating}
        style={{
          minWidth: '100px',
          margin: '4px',
          opacity:
            record.moderation_status === IceBreakerModerationStatus.Approve
              ? '0.3'
              : '1',
        }}
        onClick={handleDecline}
      >
        Decline
      </ErrorButton>

      <ConfirmationDialog
        isOpen={isConfirmationPopupOopen}
        onConfirm={(reason) =>
          handleModerate({
            reason,
            moderationStatus: IceBreakerModerationStatus.Decline,
          })
        }
        onClose={() => setIsConfirmationPopupOpen(false)}
      />
    </>
  );
};
