import React from 'react';
import { Layout, Sidebar } from 'react-admin';
import AppBar from './AppBar';
import Menu from './Menu';
import { lightTheme } from './themes';
import { makeStyles } from '@material-ui/core/styles';
import { FaviconProvider } from 'faviconsProvider';

const CustomSidebar = (props: any) => <Sidebar {...props} size={200} />;

const useStyles = makeStyles({
  content: {
    zIndex: 'auto',
    position: 'relative',
  },
});

export default (props: any) => {
  const classes = useStyles();

  return (
    <FaviconProvider>
      <Layout
        {...props}
        appBar={AppBar}
        sidebar={CustomSidebar}
        menu={Menu}
        theme={lightTheme}
        classes={classes}
      />
    </FaviconProvider>
  );
};
