import { FC, memo, useMemo, useRef, useState } from 'react';
import { useLocation } from 'react-router';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';

import { ImageListItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { MediaType } from 'types/mediaType';
import { TrustedUserMedia } from 'types/trustedUser/trustedUserModeration';

import { Env, getEnv } from 'utils';
import MediaModerationBtn from './MediaModerationBtn';
import CustomPagination from './CustomPagination';
import SuccessButton from 'layout/buttons/SuccessButton';
import ErrorButton from 'layout/buttons/ErrorButton';
import { ModerationStatus } from 'types/enums/ModerationStatus';

const PHOTOS_ON_PAGE = 30;

interface Props {
  tabNumber: number;
  media: TrustedUserMedia[];
  setMedia: (
    setStateAction: (prev: TrustedUserMedia[]) => TrustedUserMedia[],
  ) => void;
}

const useStyles = makeStyles({
  photosList: {
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 1fr)',
    gridTemplateRows: 'auto 1fr',
    gap: '10px',
    padding: '10px 0',
  },
  media: {
    display: 'flex',
    justifyContent: 'center',
    marginRight: '5px',
  },
  photoItem: {
    height: '300px',
    maxWidth: '100%',
    minWidth: '150px',
    objectFit: 'contain',
  },
  videoItem: {
    maxWidth: '100%',
    minWidth: '150px',
    height: '100%',
    objectFit: 'contain',
  },
  newBadge: {
    position: 'absolute',
    top: '0',
    right: '0',
    backgroundColor: 'red',
    color: 'white',
    padding: '5px',
  },
});

const MediaList: FC<Props> = memo(({ tabNumber, media, setMedia }) => {
  const currentEnv = getEnv();

  const { pathname } = useLocation();

  const ref = useRef<null | HTMLDivElement>(null);

  const [activePage, setActivePage] = useState(1);

  const renderedPhotos = useMemo(() => {
    return media.slice(
      PHOTOS_ON_PAGE * activePage - PHOTOS_ON_PAGE,
      PHOTOS_ON_PAGE * activePage,
    );
  }, [media, activePage]);

  const pages = useMemo(() => {
    return Array.from(
      { length: Math.ceil(media?.length / PHOTOS_ON_PAGE) || 1 },
      (_, index) => index + 1,
    );
  }, [media.length]);

  const classes = useStyles();

  const handleModerateMedia = ({
    id,
    statusId,
    isErotic,
  }: {
    id: number;
    statusId?: number;
    isErotic?: boolean;
  }) => {
    setMedia((prevMedia) =>
      prevMedia.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            is_erotic: isErotic !== undefined ? isErotic : item.is_erotic,
            status_id: statusId !== undefined ? statusId : item.status_id,
          };
        }

        return item;
      }),
    );
  };

  const handleApproveAllBtn = () => {
    setMedia((prevMedia) =>
      prevMedia.map((item) => ({
        ...item,
        status_id: ModerationStatus.Ok,
      })),
    );
  };

  const handleDeclineAllBtn = () => {
    setMedia((prevMedia) =>
      prevMedia.map((item) => ({
        ...item,
        status_id: ModerationStatus.Ban,
      })),
    );
  };

  const handlePageChange = (nextTargetPage: number) => {
    setActivePage(Number(nextTargetPage));

    if (ref?.current) {
      setTimeout(
        () =>
          ref?.current?.scrollIntoView({ behavior: 'smooth', block: 'start' }),
        400,
      );
    }
  };

  if (!media || !pathname.endsWith(`/${tabNumber}`)) {
    return null;
  }

  if (media.length === 0) {
    return <p>No media for moderation</p>;
  }

  return (
    <div ref={ref}>
      {currentEnv !== Env.Production && (
        <>
          <SuccessButton
            style={{ marginBottom: '5px' }}
            onClick={handleApproveAllBtn}
          >
            Approve all
          </SuccessButton>
          <ErrorButton
            style={{ marginBottom: '5px' }}
            onClick={handleDeclineAllBtn}
          >
            Decline all
          </ErrorButton>
        </>
      )}

      <div className={classes.photosList}>
        {renderedPhotos?.map((item) => {
          return (
            <ImageListItem key={item.id} className={classes.media}>
              {item.type === MediaType.Video ||
              item.type === MediaType.PrivateLibraryVideo ? (
                <video
                  src={item.file_url}
                  className={classes.videoItem}
                  controls
                />
              ) : (
                <Zoom>
                  <img
                    src={item.file_url}
                    className={classes.photoItem}
                    loading="lazy"
                  />
                </Zoom>
              )}

              {item.status_id === ModerationStatus.Process && (
                <div className={classes.newBadge}>New</div>
              )}

              <MediaModerationBtn
                item={item}
                handleModerateMedia={handleModerateMedia}
                isEroticCheckboxActive={
                  item.type === MediaType.PrivateLibraryPhoto ||
                  item.type === MediaType.PrivateLibraryVideo
                }
              />
            </ImageListItem>
          );
        })}
      </div>

      <CustomPagination
        pages={pages}
        activePage={activePage}
        onChangeActivePage={handlePageChange}
      />
    </div>
  );
});

export default MediaList;
