import { FC, useState } from 'react';
import { useHistory } from 'react-router';
import {
  BooleanField,
  DateField,
  TextField,
  useNotify,
  useRecordContext,
  useRefresh,
  useUpdate,
} from 'react-admin';

import {
  TrustedModerationStatus,
  TrustedUser,
} from 'types/trustedUser/trustedUserModeration';
import { Card, CardMedia, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';

import { httpClient } from 'httpClient';

import ToggleActivateButton from 'layout/buttons/ToggleActivateButton';
import ErrorButton from 'layout/buttons/ErrorButton';

import { SiteIdsSelectBlock } from './SiteIdsSelectBlock';

import 'react-medium-image-zoom/dist/styles.css';
import Toggle from 'react-toggle';
import { OnlineType } from 'types/enums/OnlineType';
import { PidvalApi } from 'api/PidvalApi';

interface Props {
  isModeration?: boolean;
}

const useStyles = makeStyles(() => ({
  asideCard: {
    display: 'flex',
    flexDirection: 'column',
    width: '16.66%',
    padding: '10px',
    border: 'none',
    overflowY: 'scroll',
  },
  mediaCard: {
    maxHeight: '500px',
    maxWidth: '300px',
    objectFit: 'contain',
  },
  onlineStatus: {
    width: '10px',
    height: '10px',
    marginLeft: '5px',
    backgroundColor: 'green',
    borderRadius: '50%',
  },
  bottomContainer: {
    marginTop: 'auto',
    display: 'flex',
    flexDirection: 'column',
  },
  button: {
    minWidth: '100px',
    margin: '4px',

    '&:disabled': {
      backgroundColor: green[50],
    },
  },
  toggle: {
    marginLeft: '10px',
  },
}));

export const ShortInfoBar: FC<Props> = ({ isModeration }) => {
  const classes = useStyles();
  const refresh = useRefresh();
  const history = useHistory();
  const notify = useNotify();

  const [update] = useUpdate();

  const record = useRecordContext<TrustedUser>();

  // TODO refactor loading statuses
  const [isUpdatingOnlineType, setIsUpdatingOnlineType] = useState(false);
  const [isUpdatingNewStatus, setIsUpdatingNewStatus] = useState(false);
  const [isUpdatingTestStatus, setIsUpdatingTestStatus] = useState(false);
  const [isUpdatingModerationStatus, setIsUpdatingModerationStatus] =
    useState(false);

  const changeTestStatus = () => {
    if (record) {
      setIsUpdatingTestStatus(true);

      httpClient
        .put(`/trusted-user/${record.id}/test`, {
          is_test: !record.is_test,
        })
        .then(() => {
          refresh();

          setIsUpdatingTestStatus(false);
        });
    }
  };

  const handleClickReModeration = () => {
    setIsUpdatingModerationStatus(true);

    if (record) {
      httpClient.put(`/trusted-user/${record.id}/re-moderation`).then(() => {
        setIsUpdatingModerationStatus(false);
        history.push(`/trusted-user/re-moderation/${record.id}`);
      });
    }
  };

  const handleClickActivateProfile = () => {
    setIsUpdatingModerationStatus(true);

    if (record) {
      httpClient
        .post(`trusted-user/published/${record.id}`, {})
        .then(() => {
          setIsUpdatingModerationStatus(false);
          refresh();
        })
        .catch((error) => {
          setIsUpdatingModerationStatus(false);

          notify(
            error.message || 'Something went wrong try change again',
            'error',
          );
        });
    }
  };

  const handleDefaultOnlineType = () => {
    setIsUpdatingOnlineType(true);

    httpClient
      .post(`/trusted-user/${record.id}/online-type`, {
        online_type: OnlineType.RealOnline,
      })
      .then(() => {
        update(
          'trusted-user',
          record.id,
          {
            is_online: false,
            online_type: OnlineType.RealOnline,
          },
          record,
        );
      })
      .finally(() => setIsUpdatingOnlineType(false));
  };

  const handleForceOnlineClick = () => {
    if (record.online_type === OnlineType.ForceOnline) {
      handleDefaultOnlineType();

      return;
    }

    setIsUpdatingOnlineType(true);

    httpClient
      .post(`/trusted-user/${record.id}/online-type`, {
        online_type: OnlineType.ForceOnline,
      })
      .then(() => {
        update(
          'trusted-user',
          record.id,
          {
            is_online: true,
            online_type: OnlineType.ForceOnline,
          },
          record,
        );
      })
      .finally(() => setIsUpdatingOnlineType(false));
  };

  const handleForceOfflineClick = () => {
    if (record.online_type === OnlineType.ForceOffline) {
      handleDefaultOnlineType();

      return;
    }

    setIsUpdatingOnlineType(true);

    httpClient
      .post(`/trusted-user/${record.id}/online-type`, {
        online_type: OnlineType.ForceOffline,
      })
      .then(() => {
        update(
          'trusted-user',
          record.id,
          {
            is_online: false,
            online_type: OnlineType.ForceOffline,
          },
          record,
        );
      })
      .finally(() => setIsUpdatingOnlineType(false));
  };

  const handleNewStatusChange = () => {
    setIsUpdatingNewStatus(true);

    if (record.facilitate_rules?.new_from) {
      PidvalApi.activateTuNewStatus({
        status: false,
        trusted_user_id: record.id,
      })
        .then(() => {
          update(
            'trusted-user',
            record.id,
            {
              facilitate_rules: {
                new_from: null,
              },
            },
            record,
          );
        })
        .finally(() => {
          setIsUpdatingNewStatus(false);
        });

      return;
    }

    const isoDate = new Date().toISOString();
    const newFrom = `${isoDate.substring(0, 10)} ${isoDate.substring(11, 19)}`;

    PidvalApi.activateTuNewStatus({
      status: true,
      trusted_user_id: record.id,
      new_from: newFrom,
    })
      .then(() => {
        update(
          'trusted-user',
          record.id,
          {
            facilitate_rules: {
              new_from: newFrom,
            },
          },
          record,
        );
      })
      .finally(() => {
        setIsUpdatingNewStatus(false);
      });
  };

  if (!record) return null;

  return (
    <Card className={classes.asideCard}>
      <Box marginBottom="10px">
        Id: <TextField source="id" />
      </Box>

      <Box marginBottom="10px">
        <Box>
          Carthage Id:{' '}
          <TextField
            source="external_id"
            style={{ color: 'rgb(59,130,246)' }}
          />
        </Box>
        <Box>
          Supply Id: <TextField source="supply.id" />
        </Box>
        <Box>
          Supply name:{' '}
          <TextField source="supply.name" style={{ fontWeight: 'bold' }} />
        </Box>
      </Box>

      <Box>
        Registration date: <DateField source="created_at" locales="UK" />
      </Box>

      <Box display="flex" marginBottom="10px">
        Gift available:{' '}
        <BooleanField source="gift_available" textAlign="center" />
      </Box>

      {!isModeration && (
        <Box display="flex" alignItems="center" marginBottom="5px">
          Force_<b>online</b>:
          <Toggle
            checked={record.online_type === OnlineType.ForceOnline}
            onChange={handleForceOnlineClick}
            disabled={isUpdatingOnlineType}
            className={classes.toggle}
          />
        </Box>
      )}

      {!isModeration && (
        <Box display="flex" alignItems="center" marginBottom="10px">
          Force_<b>offline</b>:{' '}
          <Toggle
            checked={record.online_type === OnlineType.ForceOffline}
            onChange={handleForceOfflineClick}
            disabled={isUpdatingOnlineType}
            className={classes.toggle}
          />
        </Box>
      )}

      {!isModeration && (
        <Box
          display="flex"
          flexWrap="wrap"
          alignItems="center"
          marginBottom="10px"
        >
          Is New:
          <Toggle
            checked={!!record.facilitate_rules?.new_from}
            onChange={handleNewStatusChange}
            disabled={isUpdatingNewStatus}
            className={classes.toggle}
          />
          {!!record.facilitate_rules?.new_from && (
            <Box width="100%" fontWeight="600" marginTop="3px">
              [{record.facilitate_rules.new_from}]
            </Box>
          )}
        </Box>
      )}

      <CardMedia
        component="img"
        image={record.avatar}
        alt="image"
        className={classes.mediaCard}
      />

      <SiteIdsSelectBlock
        userId={record.id}
        userSiteIds={record.site_ids}
        availableSupplySiteIds={record?.supply.site_ids}
      />

      <div className={classes.bottomContainer}>
        {record.is_online && (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              marginBottom: '10px',
            }}
          >
            Online <p className={classes.onlineStatus} />
          </div>
        )}

        <ErrorButton
          className={classes.button}
          disabled={
            isUpdatingModerationStatus ||
            record.moderation_status !== TrustedModerationStatus.Prepared
          }
          onClick={handleClickActivateProfile}
        >
          Publish
        </ErrorButton>

        <ToggleActivateButton
          isActivate={!!record.is_test}
          handleActivate={changeTestStatus}
          handleDeactivate={changeTestStatus}
          isLoader={isUpdatingTestStatus}
          activateLabel="Deactivate is_test"
          deactivateLabel="Activate is_test"
        />

        <ErrorButton
          className={classes.button}
          onClick={handleClickReModeration}
          disabled={
            isUpdatingModerationStatus ||
            record.moderation_status === TrustedModerationStatus.ReModeration ||
            record.moderation_status === TrustedModerationStatus.Moderation
          }
        >
          RE-MODERATE
        </ErrorButton>
      </div>
    </Card>
  );
};
