import React from 'react';
import { FC } from 'react';
import { Edit, ResourceComponentPropsWithId } from 'react-admin';
import ActivityConfigForm from './ActivityConfigForm';

const ConfigTitle: FC<any> = ({ record }) =>
  record ? <span>IcebreakerConfig #{record.id}</span> : null;

const ActivityConfigEdit: FC<ResourceComponentPropsWithId> = (props) => {
  return (
    <Edit title={<ConfigTitle />} {...props}>
      <ActivityConfigForm />
    </Edit>
  );
};

export default ActivityConfigEdit;
