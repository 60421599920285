import {
  IceBreakerDeclineReason,
  IceBreakersTypes,
} from './enums/IceBreakersTypes';

export const IceBreakersTypesCaptions = {
  [IceBreakersTypes.Chat]: 'Chat',
  [IceBreakersTypes.Mail]: 'Mail',
  [IceBreakersTypes.MissedMessage]: 'Missed message',
  [IceBreakersTypes.ChatFollowUp]: 'Chat follow-up',
  [IceBreakersTypes.ChatOnline]: 'Chat online',
};

export const declineReasons = [
  { reason: IceBreakerDeclineReason.DUPLICATE, caption: 'Duplicate' },
  {
    reason: IceBreakerDeclineReason.EXCESSIVE_PASSION,
    caption: 'Excessive passion',
  },
  { reason: IceBreakerDeclineReason.ADULT_CONTENT, caption: 'Adult content' },
  { reason: IceBreakerDeclineReason.USER_SPECIFIC, caption: 'User-specific' },
  { reason: IceBreakerDeclineReason.REAL_MEETING, caption: 'Real meeting' },
  {
    reason: IceBreakerDeclineReason.FORBIDDEN_CONTENT,
    caption: 'Forbidden content',
  },
  { reason: IceBreakerDeclineReason.CONTACT_INFO, caption: 'Contact info' },
  { reason: IceBreakerDeclineReason.EXTORTION, caption: 'Extortion' },
  {
    reason: IceBreakerDeclineReason.FORMATTING_ISSUE,
    caption: 'Formatting issue',
  },
  { reason: IceBreakerDeclineReason.WRONG_TU_INFO, caption: 'Wrong TU info' },
  { reason: IceBreakerDeclineReason.NON_ENGLISH, caption: 'Non-English' },
];
