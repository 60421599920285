import React from 'react';

import {
  Datagrid,
  List,
  TextField,
  NumberField,
  DateField,
  useRecordContext,
  Filter,
  NumberInput,
  SelectInput,
} from 'react-admin';
import CustomPagination from 'layout/Pagination';
import { Link } from 'react-router-dom';
import GreyButton from '../../layout/buttons/GreyButton';
import {
  activityAction,
  activityStatus,
  activityStatusReason,
  activityType,
} from './field-choices';

const ByUserButton = () => {
  const record = useRecordContext();
  return (
    <GreyButton
      size={'small'}
      component={Link}
      to={`/activity/user/${record.user_id}`}
      color="primary"
    >
      <TextField source="user_id" textAlign="center" sortable={false} />
    </GreyButton>
  );
};

const ActivityFilter = (props: any) => (
  <Filter {...props}>
    <NumberInput source="user_id" alwaysOn style={{ width: '100px' }} />
    <SelectInput label={'Type'} source="type" choices={activityType} alwaysOn />
    <SelectInput
      label={'Action'}
      source="action"
      choices={activityAction}
      alwaysOn
    />
    <NumberInput source="source_user_id" alwaysOn style={{ width: '100px' }} />
    <SelectInput
      label={'Status'}
      source="status"
      choices={activityStatus}
      alwaysOn
    />
    <SelectInput
      label={'Status reason'}
      source="status_reason"
      choices={activityStatusReason}
      alwaysOn
    />
  </Filter>
);

const ActivityList = (props: any) => {
  return (
    <List
      filters={<ActivityFilter />}
      sort={{ field: 'updated_at', order: 'DESC' }}
      pagination={<CustomPagination />}
      bulkActionButtons={false}
      perPage={30}
      {...props}
    >
      <Datagrid>
        <NumberField
          source="id"
          options={{ useGrouping: false }}
          sortable={false}
          textAlign="center"
        />
        <ByUserButton />
        <TextField source="event" textAlign="center" sortable={false} />
        <TextField source="type" textAlign="center" sortable={false} />
        <TextField source="action" textAlign="center" sortable={false} />
        <NumberField
          source="source_user_id"
          options={{ useGrouping: false }}
          sortable={false}
          textAlign="center"
        />
        <NumberField
          source="supply_id"
          options={{ useGrouping: false }}
          sortable={false}
          textAlign="center"
          label={'Supply Id'}
        />
        <TextField source="status" textAlign="center" />
        <TextField source="status_reason" textAlign="center" sortable={false} />

        <DateField
          source="created_at"
          locales="uk-UA"
          showTime
          sortable={false}
        />
      </Datagrid>
    </List>
  );
};

export default ActivityList;
