import React, { useState, useEffect, useCallback } from 'react';
import Zoom from 'react-medium-image-zoom';

import {
  Card,
  CardContent,
  Grid,
  Chip,
  Box,
  CircularProgress,
} from '@material-ui/core';
import {
  ThumbUp,
  RemoveCircle,
  Delete,
  SkipNext,
  RotateRight,
} from '@material-ui/icons';
import { Title, useAuthenticated, useNotify } from 'react-admin';
import { EventEmitter } from 'eventEmitter';
import { httpClient } from '../../../httpClient';
import { AxiosResponse } from 'axios';
import SuccessButton from '../../../layout/buttons/SuccessButton';
import ErrorButton from '../../../layout/buttons/ErrorButton';
import GreyButton from '../../../layout/buttons/GreyButton';
import ImageGridList from './ImageGridList';
import { useHistory, useParams } from 'react-router-dom';
import { UserShort, Photo, UserAdditionalInfo } from '../../../model-types';
import Gender from '../../../layout/Gender';
import { MediaType } from 'types/mediaType';
import { EmitterEvents } from 'types/enums/EmitterEvents';
import { ModerationStatus } from 'types/enums/ModerationStatus';

const UserPhotoModeration = () => {
  const history = useHistory();

  const { initPhotoId: paramsPhotoId }: any = useParams<{
    initPhotoId: string;
  }>();

  const notify = useNotify();

  const [isLoading, setIsLoading] = useState(false);

  const [photo, setPhoto] = useState<Photo | null>(null);

  const [tiles, setTiles] = useState<Photo[]>([]);
  const [user, setUser] = useState<UserShort | null>(null);
  const [userAdditionalInfo, setUserAdditionalInfo] =
    useState<UserAdditionalInfo | null>(null);
  const [unModeratedCount, setUnModeratedCount] = useState<number | null>(null);
  const [lastModeratedAt, setLastModeratedAt] = useState<Date | null>(null);

  const changePhoto = (response: AxiosResponse) => {
    const newPhotoId =
      response.data.photo?.id || response.data.media?.id || null;
    const newUrl = `/photo/moderation/${newPhotoId || 'user'}`;

    setUser({ ...response.data.user, user_id: response.data.user_id });
    setTiles(response.data.photos ?? []);
    setPhoto((response.data.photo || response.data.media) ?? null);
    setUnModeratedCount(response.data.unmoderated_count);
    setLastModeratedAt(new Date(response.data.last_moderated_at));

    if (!document.URL.endsWith(newUrl)) {
      history.push(newUrl);
    }

    setIsLoading(false);
  };

  const changePhotoWithCounters = (response: AxiosResponse) => {
    changePhoto(response);
    EventEmitter.dispatch(EmitterEvents.ReloadModerationCounters);
  };

  const getPhoto = (photoId: number | null) => {
    httpClient.get(`/photo/moderation/${photoId || 'user'}`).then(changePhoto);
  };

  const handleError = (err: any) => {
    notify(
      err?.message || 'Oops, something went wrong. Reload page and try again!',
    );

    setIsLoading(false);
  };

  const changeAdditionalUserInfo = (response: AxiosResponse) => {
    if (response.data) setUserAdditionalInfo(response.data ?? null);
  };

  const approve = () => {
    if (!photo || isLoading) return;

    setIsLoading(true);

    httpClient
      .get('/photo/moderation/approve/' + photo.id)
      .then(changePhotoWithCounters)
      .catch(handleError);
  };

  const ban = () => {
    if (!photo || isLoading) return;

    setIsLoading(true);

    httpClient
      .get('/photo/moderation/ban/' + photo.id)
      .then(changePhotoWithCounters)
      .catch(handleError);
  };

  const remove = () => {
    if (!photo || isLoading) return;

    setIsLoading(true);

    httpClient
      .get('/photo/moderation/delete/' + photo.id)
      .then(changePhotoWithCounters)
      .catch(handleError);
  };

  const skip = () => {
    if (!photo || isLoading) return;

    setIsLoading(true);

    httpClient
      .get('/photo/moderation/skip/' + photo.id)
      .then(changePhoto)
      .catch(handleError);
  };

  const rotate = () => {
    if (!photo || isLoading) return;

    setIsLoading(true);

    httpClient
      .get('/photo/moderation/rotate/' + photo.id)
      .then(changePhoto)
      .catch(handleError);
  };

  const getAdditionalUserInfo = useCallback(() => {
    if (user?.user_id) {
      httpClient
        .get(`/users/specific/${user.user_id}`)
        .then(changeAdditionalUserInfo);
    }
  }, [user?.user_id]);

  useAuthenticated();

  useEffect(() => {
    if (!!photo && photo?.moderation_status !== ModerationStatus.Process) {
      notify('Oops, this media has already been moderated!');
    }
  }, [photo]);

  useEffect(() => {
    if (paramsPhotoId) {
      getPhoto(paramsPhotoId);
    }
  }, [paramsPhotoId]);

  useEffect(() => {
    EventEmitter.subscribe(EmitterEvents.PhotoModerationReload, () => {
      if (!photo) getPhoto(null);
    });

    return () => {
      EventEmitter.unsubscribe(EmitterEvents.PhotoModerationReload);
    };
  }, [photo]);

  useEffect(getAdditionalUserInfo, [getAdditionalUserInfo]);

  return (
    <Card>
      <Title title="User Photo Moderation" />
      <CardContent>
        {photo && user && (
          <Grid container spacing={3}>
            <Grid item xs={8} container direction="row" alignItems="center">
              <SuccessButton
                endIcon={<ThumbUp />}
                onClick={approve}
                disabled={isLoading}
              >
                Approve
              </SuccessButton>
              <GreyButton
                endIcon={<RotateRight />}
                onClick={rotate}
                disabled={isLoading}
              >
                Rotate
              </GreyButton>
              <ErrorButton
                endIcon={<RemoveCircle />}
                onClick={ban}
                disabled={isLoading}
              >
                Ban
              </ErrorButton>
              <ErrorButton
                endIcon={<Delete />}
                onClick={remove}
                disabled={isLoading}
              >
                Delete
              </ErrorButton>
              <GreyButton
                endIcon={<SkipNext />}
                onClick={skip}
                disabled={isLoading}
              >
                Skip
              </GreyButton>

              {isLoading && (
                <Box margin="0 10px">
                  <CircularProgress size={24} />
                </Box>
              )}
            </Grid>

            <Grid
              item
              xs={4}
              container
              direction="row"
              alignItems="center"
              justifyContent="flex-end"
            >
              <Chip
                label={unModeratedCount + ' remains'}
                variant="outlined"
                style={{ margin: '5px' }}
              />
              <Chip
                label={'Last Moderation: ' + lastModeratedAt?.toLocaleString()}
                variant="outlined"
                style={{ margin: '5px' }}
              />
            </Grid>
            <Grid item xs={12}>
              <Box>
                <div>
                  #{user.user_id}, {user.name}, <Gender type={user.gender} />,{' '}
                  Age: {user.age}, Country:{' '}
                  {userAdditionalInfo?.country_name || '-'}, Host:{' '}
                  {userAdditionalInfo?.host || '-'}
                </div>
              </Box>

              <Box marginTop="10px">
                Type: {MediaType[photo?.media_type]}{' '}
                {!!photo.is_main && <Chip label="Avatar" variant="outlined" />}
              </Box>
              {user.details?.about_me && (
                <Box marginTop="10px">
                  Profile description: {user.details.about_me}
                </Box>
              )}
            </Grid>
            <Grid item xs={7}>
              <Zoom>
                <img
                  src={photo.big_url}
                  alt={'#' + user.user_id}
                  style={{
                    maxWidth: '100%',
                    minWidth: '50%',
                    maxHeight: 'calc(100vh - 300px)',
                    objectFit: 'contain',
                    objectPosition: 'left',
                  }}
                />
              </Zoom>
            </Grid>
            <Grid item xs={5}>
              <ImageGridList tiles={tiles} />
            </Grid>
          </Grid>
        )}
      </CardContent>
    </Card>
  );
};

export default UserPhotoModeration;
