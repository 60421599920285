import { mergeTranslations } from 'react-admin';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import englishMessages from 'ra-language-english';

const customMessages: any = {};
const i18nProvider = polyglotI18nProvider(
  () => mergeTranslations(englishMessages, customMessages),
  'en',
  { allowMissing: true, onMissingKey: (key: string) => key },
);

export default i18nProvider;
